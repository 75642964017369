import ExpandMore from '@material-symbols/svg-400/outlined/keyboard_arrow_down.svg';

const MockDropdown = ({ text, placeholder }: { text: string; placeholder: boolean }) =>
{
	return (
		<div className='flex flex-row w-full items-center justify-between border p-2 border-neutral-200 rounded-md'>
			<p className=''>{text}</p>
			<p className='text-icon'>
				<ExpandMore />
			</p>
		</div>
	);
};

export default MockDropdown;