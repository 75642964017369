import(/* webpackMode: "eager" */ "/vercel/path0/app/dashboard.css");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/vercel/path0/components/LandingPage/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NavLinks"] */ "/vercel/path0/components/LandingPage/NavLinks.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PrimaryFeatures"] */ "/vercel/path0/components/LandingPage/PrimaryFeatures.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Accordion","AccordionItem","AccordionTrigger","AccordionContent"] */ "/vercel/path0/components/ui/accordion.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@material-symbols/svg-400/outlined/send.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
