import { Button } from '@/components/ui/button';
import BookSpark from '@material-symbols/svg-400/outlined/book_4_spark.svg';

const MockResearchColumnPanel = () =>
{
	const name = undefined;
	const description = undefined;
	const instructions = undefined;
	const existingColumn = undefined;

	return (
		<div className='pointer-events-none text-neutral-900 p-2 h-[600px]'>
			<p className='flex flex-row items-center gap-x-2 text-xl font-semibold'>
				<span className='text-icon'>
					<BookSpark />
				</span>
				AI Column
			</p>
			<p className='text-sm text-neutral-500'>
				Specify the details you’d like to understand for each record in your list. Our AI will research and automatically populate the column with
				tailored information based on your custom instructions.
			</p>

			<p className='pt-2'>Column Name</p>
			<div className='w-full border border-neutral-200 rounded-md p-2'>{name || 'Enter a name for the column'}</div>

			<p className='pt-4'>Column Description</p>
			<div className='w-full border border-neutral-200 rounded-md p-2'>{description || 'Enter a description for the column'}</div>

			<p className='pt-2'>Instructions</p>
			<div className='w-full border border-neutral-200 rounded-md p-2'>{instructions || 'Enter instructions for the column'}</div>

			<p className='py-2 text-base text-neutral-500 pt-4'>
				When you create this column, our AI will research each record in your list, using our BioPharma database, to automatically generate and populate
				the most relevant value based on your specified criteria.
			</p>

			<div className='flex flex-col w-full justify-between gap-2 pt-8'>
				<Button variant={'primary'} className='px-8'>
					Start Research
				</Button>
				{/* <Button variant={'outline'} className='px-8'>
			Show me an Example
		</Button> */}
			</div>
		</div>
		// <div className='pointer-events-none text-neutral-900 p-2 h-[600px]'>
		// 	<p className='flex flex-row items-center gap-x-2 text-xl font-semibold'>
		// 		<span className='text-icon'>
		// 			<IntegrationInstructions />
		// 		</span>
		// 		AI Column
		// 	</p>
		// 	<p className='text-sm text-neutral-500'>
		// 		Specify the details you’d like to learn for each record in your list. Our AI will research and automatically populate the column with tailored
		// 		insights based on your custom criteria.
		// 	</p>

		// 	<p className='pt-2'>Column Name</p>
		// 	<div className='w-full border border-neutral-200 rounded-md p-2'>{name || 'Enter a name for the column'}</div>

		// 	<p className='pt-4'>Column Description</p>
		// 	<div className='w-full border border-neutral-200 rounded-md p-2'>{description || 'Enter a description for the column'}</div>

		// 	<p className='pt-2'>Instructions</p>
		// 	<div className='w-full border border-neutral-200 rounded-md p-2'>{instructions || 'Enter instructions for the column'}</div>

		// 	<p className='py-2 text-base text-neutral-500 pt-4'>
		// 		When you create this column, our AI will research each record in your list, using our BioPharma database, to automatically generate and populate
		// 		the most relevant value based on your specified criteria.
		// 	</p>

		// 	<div className='flex flex-col w-full justify-between gap-2 pt-4 flex-grow'>
		// 		<Button variant={'primary'} className='px-8'>
		// 			Start Research
		// 		</Button>
		// 	</div>
		// </div>
	);
};

export default MockResearchColumnPanel;
