import PartnerExchange from '@material-symbols/svg-400/outlined/partner_exchange.svg';
import CoPresent from '@material-symbols/svg-400/outlined/co_present.svg';
import Article from '@material-symbols/svg-400/outlined/article.svg';
import HistoryEdu from '@material-symbols/svg-400/outlined/history_edu.svg';
import DesktopWindows from '@material-symbols/svg-400/outlined/desktop_windows.svg';
import SignalCellularAlt from '@material-symbols/svg-400/outlined/signal_cellular_alt.svg';
import MonitorHeart from '@material-symbols/svg-400/outlined/monitor_heart.svg';
import BubbleChart from '@material-symbols/svg-400/outlined/bubble_chart.svg';
import RequestQuote from '@material-symbols/svg-400/outlined/request_quote.svg';
import Map from '@material-symbols/svg-400/outlined/map.svg';
import News from '@material-symbols/svg-400/outlined/newsmode.svg';
import Pill from '@material-symbols/svg-400/outlined/pill.svg';
import Poster from '@material-symbols/svg-400/outlined/table_chart.svg';
import Apartment from '@material-symbols/svg-400/outlined/apartment.svg';
import Recording from '@material-symbols/svg-400/outlined/record_voice_over.svg';
import Government from '@material-symbols/svg-400/outlined/account_balance.svg';
import Lightbulb from '@material-symbols/svg-400/outlined/lightbulb.svg';
import Microbiology from '@material-symbols/svg-400/outlined/microbiology.svg';
import Groups3 from '@material-symbols/svg-400/outlined/groups_3.svg';
import BookmarkStar from '@material-symbols/svg-400/outlined/bookmark_star.svg';
import BookSpark from '@material-symbols/svg-400/outlined/book_4_spark.svg';
import Search from '@material-symbols/svg-400/outlined/search.svg';
import Target from '@material-symbols/svg-400/outlined/target.svg';
import Book2 from '@material-symbols/svg-400/outlined/book_2.svg';
import MapIcon from '@material-symbols/svg-400/outlined/map.svg';
import Keyword from '@material-symbols/svg-400/outlined/spellcheck.svg';
import DataUsage from '@material-symbols/svg-400/outlined/data_usage.svg';
import DonutLarge from '@material-symbols/svg-400/outlined/donut_large.svg';
import DataSaverOn from '@material-symbols/svg-400/outlined/data_saver_on.svg';
import Unpublished from '@material-symbols/svg-400/outlined/unpublished.svg';
import CheckCircle from '@material-symbols/svg-400/outlined/check_circle.svg';
import DateRange from '@material-symbols/svg-400/outlined/date_range.svg';
import CorporateFare from '@material-symbols/svg-400/outlined/corporate_fare.svg';
import Web from '@material-symbols/svg-400/outlined/web.svg';
import Prescriptions from '@material-symbols/svg-400/outlined/prescriptions.svg';
import Rubric from '@material-symbols/svg-400/outlined/rubric.svg';
import Flag from '@material-symbols/svg-400/outlined/flag.svg';
import PostAdd from '@material-symbols/svg-400/outlined/post_add.svg';
import SportsScore from '@material-symbols/svg-400/outlined/sports_score.svg';
import EditSquare from '@material-symbols/svg-400/outlined/edit_square.svg';
import Mail from '@material-symbols/svg-400/outlined/mail.svg';

interface IconKey
{
	[key: string]: any;
}

const iconKey: IconKey = {
	//Document types
	journal_article: HistoryEdu,
	press_release: News,
	sec_filing: Government,
	earnings_call_transcript: Recording,
	patent: Lightbulb,
	collaboration: PartnerExchange,
	company_presentation: CoPresent,
	presentation: CoPresent,
	scientific_poster: Poster,
	trial: Prescriptions,

	//Drug Detail Top Row Icons
	product_status: DesktopWindows,
	product_phase: SignalCellularAlt,
	product_type: MonitorHeart,
	product_therapeutic_class: BubbleChart,
	therapeutic_class: BubbleChart,
	deal: RequestQuote,
	landscape: Map,
	unpublished: Unpublished,
	published: CheckCircle,
	clinical_pipeline: Rubric,
	clinical_pipeline_analysis: Rubric,
	disease_overview: Microbiology,
	epidemiology: Groups3,
	fda_label_analysis: BookmarkStar,
	report_builder: BookSpark,

	//Session type
	search: Search,
	comps: Target,
	list: Book2,
	question: MapIcon,
	keyword: Keyword,
	drug: Pill,
	market_map: DataUsage,
	radar_chart: Target,
	target: Target,
	target_list: Target,

	//Search focus filters
	company: CorporateFare,
	date: DateRange,
	doc_type: Article,
	tab: Web,

	//Trial Event
	start: Flag,
	first_posted: PostAdd,
	complete: SportsScore,
	update: EditSquare,

	mail: Mail
};

export default function getIcon(icon_key: string)
{
	if (!iconKey[icon_key]) return iconKey['journal_article'];
	return iconKey[icon_key];
}

function hasIcon(icon_key: string)
{
	if (iconKey[icon_key]) return true;
	return false;
}

export { hasIcon };
