import formatName from '@/utils/format/formatName';
import getIcon from '@/utils/getIcon';
import MockDropdown from './MockDropdown';
import { Button } from '@/components/ui/button';

import Search from '@material-symbols/svg-400/outlined/search.svg';
import ViewList from '@material-symbols/svg-400/outlined/view_list.svg';

const MockListBuilder = () =>
{
	const tabs = ['drug', 'document', 'company']; // 'trial'];

	return (
		<div className='flex flex-col gap-4 p-2 text-neutral-900 pointer-events-none h-[600px]'>
			<div>
				<p className='flex flex-row items-center gap-2 text-xl font-semibold'>
					<span className='text-icon'>
						<ViewList />
					</span>{' '}
					List Builder
				</p>
				<p className='text-sm text-neutral-500'>Build interactive lists of companies, drugs, documents, and trials</p>
			</div>
			<div className='flex flex-row bg-neutral-100 items-center justify-between p-2'>
				{tabs.map((tab, idx) =>
				{
					const Icon = getIcon(tab);

					return (
						<div
							key={idx}
							className={'flex flex-row items-center gap-x-2 text-sm p-1 rounded-md ' + (idx === 0 ? 'bg-white' : ' text-neutral-500')}
						>
							<span className='text-icon'>
								<Icon />
							</span>
							<p>{formatName(tab)}</p>
						</div>
					);
				})}
			</div>

			<div className='flex flex-col gap-y-1'>
				<p className='w-full border border-neutral-200 rounded-md p-2 h-10 text-neutral-500'>Enter a search query (optional)</p>
			</div>

			<div className='flex flex-col gap-y-1'>
				<p>Mechanism</p>
				<MockDropdown text='Any Mechanism' placeholder={false} />
			</div>

			<div className='flex flex-col gap-y-1'>
				<p>Indication</p>
				<MockDropdown text='Any Indication' placeholder={false} />
			</div>

			<div className='flex flex-col gap-y-1'>
				<p>Drug Class</p>
				<MockDropdown text='Any Drug Class' placeholder={false} />
			</div>

			<div className='flex w-full items-center justify-center'>
				<Button variant={'primary'} className='px-8 w-full'>
					<span className='text-icon'>
						<Search />
					</span>
					<p>Search</p>
				</Button>
			</div>
		</div>
	);
};

export default MockListBuilder;
